import { popupStore } from "@/stores";
import { isValidEmail } from "@/utils/isValidEmail";
import Image from "next/image";
import { useState } from "react";
import { ColorRing } from "react-loader-spinner";

const Newsletter = () => {
    const [email, setEmail] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [validEmail, setValidEmail] = useState(true);

    const handleFormSubmit = async () => {
        setSubmitted(false);
        if (isValidEmail(email)) {
            setValidEmail(true);
            setIsLoading(true);
            popupStore.setContent("Subscription Added!", false, 2000);

            //async function here
            setIsLoading(false);
            setSubmitted(true);
        } else {
            setValidEmail(false);
            popupStore.setContent("Invalid Email", true, 2000);
        }
    };
    return (
        <section
            id="newsletter"
            className="newsletter-gradient overflow-clip relative sm:flex items-center justify-start lg:gap-[70px] lg:px-[45px] lg:py-[41px]  my-[24px] max-w-7xl lg:mx-auto rounded-[20px] py-5 px-4 mx-[13px]"
        >
            <div className="absolute bottom-0 z-0 hidden lg:grid -right-7">
                <img
                loading="lazy"
                    height={400}
                    width={400} 
                    src="/newsletter/mobile-mailicon-4.svg"
                    alt="Mail icon decoration"
                    className="object-contain"
                />
            </div>

            <div className="w-full flex items-start justify-center lg:max-w-[700px] relative flex-col">
                <div className="flex items-center justify-center">
                    <div className="space-y-[5px]">
                        <h3 className="text-white lg:font-semibold lg:text-3xl font-semibold text-lg leading-[21px]">
                            Be the first to receive offers.
                        </h3>
                        <p className="text-sm leading-[18px] lg:max-w-sm text-white lg:text-xl opacity-70">
                            Subscribe now to receive alerts about the latest
                            deals, special offers, and promotions.
                        </p>
                    </div>
                    <div className="grid lg:hidden ">
                        <img
                        loading="lazy"
                            height={200}
                            width={200}
                            src="/newsletter/mobile-mailicon-4.svg"
                            alt="Mail icon decoration"
                        />
                    </div>
                </div>
                <form className="relative flex mt-[14px] lg:mt-[27px] items-center w-full justify-start  gap-[9px]">
                    <div
                        className={`w-full bg-white flex gap-[14px] items-center justify-start rounded-[10px] lg:rounded-[20px] border border-[#D9D9D9] overflow-clip py-[13px] lg:py-[21px] px-6 lg:px-9 max-h-[44px] lg:max-h-[64px] ${
                            validEmail ? "" : "border-red-500"
                        } ${submitted ? "border-green-500" : ""}`}
                    >
                        <div className="h-[18px] w-[18px] lg:h-[24px] lg:w-[24px]">
                            <Image
                                src="/newsletter/mail.svg"
                                height={24}
                                width={24}
                                alt="Mail icon"
                            />
                        </div>
                        <input
                            type="email"
                            className={`h-full max-h-[40px] lg:max-h-[64px]  text-[16px] lg:text-[20px] leading-[15px] w-full  border-none  text-my-dark-blue placeholder:text-[14px] lg:placeholder:text-[16px] lg:placeholder:opacity-75 lg:placeholder:font-[400] placeholder:font-[500] placeholder:text-my-dark-blue focus:ring-0 focus:border-none focus:outline-none`}
                            placeholder="Enter email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {isLoading && (
                            <div className="translate-x-6">
                                <ColorRing
                                    colors={[
                                        "#0057ff",
                                        "#0057ff",
                                        "#0057ff",
                                        "#0057ff",
                                        "#0057ff",
                                    ]}
                                    height={40}
                                    width={40}
                                />
                            </div>
                        )}
                    </div>

                    <button
                        name="submit"
                        type="submit"
                        className="font-semibold text-xs lg:text-[18px] h-full py-[13px] lg:py-[21px] px-6 lg:px-9 text-[#150035] transition duration-200  rounded-[10px] lg:rounded-[20px] search-btn-yellow-gradient cursor-pointer"
                        onClick={(e) => {
                            e.preventDefault();
                            handleFormSubmit();
                        }}
                    >
                        Subscribe
                    </button>
                </form>
                {/* {submitted  && !isValidEmail() && (<p>Please enter valid email</p>)} */}
            </div>
        </section>
    );
};

export default Newsletter;
